import {
  createStore, applyMiddleware, compose
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { rootReducer } from '@imprivata/common-auth-ui';

const basename = window.location.href.indexOf('/prerelease') > -1 ? '/prerelease' : '/';

export const history = createBrowserHistory({ basename, });

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension() );
  }
}

const composedEnhancers = compose(
  applyMiddleware(... middleware),
  ... enhancers
);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
