import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthError } from '@imprivata/nucleus';
import AlertImage from '../images/alert.png';
import { actions } from '@imprivata/common-auth-ui';
import { postExitUIMessage } from '../embed/embed';

/**
 * AuthErrorPage Component
 */
export class AuthErrorPage extends Component {
  /**
   * The allowed properties for the AuthErrorPage component
   * @param {function} startSession The function that begins the session
   * @param {string} accountToken The account token for the session
   * @param {string} cloudauthEnvironment the applex cloud we are connecting to. Examples are dev, integration, staging, and prod
   * @param {string} selfServiceEnvironment the self service cloud we are connecting to. Examples are dev, integration, staging, and prod
   * @param {object} sessionException The session error that got thrown
   */
  static propTypes = {
    startSession: PropTypes.func,
    accountToken: PropTypes.string,
    cloudauthEnvironment: PropTypes.string,
    selfServiceEnvironment: PropTypes.string,
    sessionException: PropTypes.object,
  };

  acceptClick = () => {
    this.props.startSession(this.props.accountToken, this.props.cloudauthEnvironment, this.props.selfServiceEnvironment);
  };
  hideUI = () => {
    postExitUIMessage();
  };
  render() {
    const cleanException = this.props.sessionException && this.props.sessionException.code ? this.props.sessionException.code : 'unknownError';

    return (
      <div className='content-body'>
        <AuthError
          headerImage={AlertImage}
          headerTitle='Sorry'
          headerDescription={
            <div>
              <div>There is a problem.</div>
              <div>Refresh your browser and if that doesn't work, contact your help desk.</div>
              <div>({cleanException})</div>
            </div>
          }
          acceptText=''
          onAcceptClick={this.acceptClick}
          linkText={'Try another way'}
          onLinkClick={this.hideUI}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountToken: state.login.accountToken,
    cloudauthEnvironment: state.login.cloudauthEnvironment,
    selfServiceEnvironment: state.login.selfServiceEnvironment,
    sessionException: state.login.sessionException,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSession: (accountToken, cloudauthEnvironment, selfServiceEnvironment) => {
      dispatch(actions.startSession(accountToken, cloudauthEnvironment, selfServiceEnvironment) );
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthErrorPage)
);
