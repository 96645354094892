import React from 'react';
import { connect } from 'react-redux';
import {
  Route, Switch, withRouter
} from 'react-router-dom';

import Main from './Main';
import './App.css';

export class App extends React.Component {
  render() {
    return (
      <div className='app'>
        <Switch>
          <Route path='/auth/:token/:cloudauthEnv/:selfServiceEnv' component={Main} />
          <Route component={Main} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect()(App) );
