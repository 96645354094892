export function postMessageToPage(username, password, authenticationToken) {
  window.parent.postMessage({
    username,
    password,
    authenticationToken,
  }, '*');
}

export function postExitUIMessage() {
  window.parent.postMessage({ 'exit': true, }, '*');
}
