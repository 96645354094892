import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch, withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  actions,
  ChooseModality,
  DeviceList,
  Email,
  Footer,
  ImprivataIdEnroll,
  ImprivataIdManual,
  ImprivataIdPush,
  Otp,
  Password,
  PasswordReset,
  Pin,
  Sms,
  SmsEnroll,
  TempCode,
  UsernamePassword
} from '@imprivata/common-auth-ui';
import AuthError from './AuthError';

import { LoadingOverlay } from '@imprivata/nucleus';
import { postMessageToPage } from '../embed/embed';
class Main extends Component {
  static propTypes = {
    startSession: PropTypes.func,
    modality: PropTypes.string,
    sessionException: PropTypes.object,
    sessionStarted: PropTypes.bool,
    startSessionEstablishing: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string,
    authenticationToken: PropTypes.string,
    complete: PropTypes.bool,
    accountToken: PropTypes.string,
    cloudauthEnvironment: PropTypes.string,
    isLoading: PropTypes.bool,
  };

  async componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks);
    window.addEventListener('beforeunload', this.handleWindowClose);
    await this.setToken();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameTasks);
    window.removeEventListener('beforeunload', this.handleWindowClose);
  }

  handleFrameTasks = (event) => {
    if (event.data.accountToken) {
      this.props.setAccountToken(event.data.accountToken, event.data.cloudauthEnvironment, event.data.selfServiceEnvironment);
    }
    if (event.data.populatedUsername) {
      this.props.setUsername(event.data.populatedUsername);
    }
    if (event.data.populatedPassword) {
      this.props.setPassword(event.data.populatedPassword);
    }
  }

  handleWindowClose = () => {
    if (this.props.session != null) {
      this.props.session.close();
    }
  }

  async componentDidUpdate(prevProps) {
    if ( (!this.props.sessionStarted && !this.props.sessionException && !this.props.startSessionEstablishing) ||
      (prevProps.accountToken !== this.props.accountToken ||
      prevProps.cloudauthEnvironment !== this.props.cloudauthEnvironment) ) {
      await this.startSession();
    }
    if (!prevProps.complete && this.props.complete) {
      postMessageToPage(this.props.username, this.props.password, this.props.authenticationToken);
    }
  }

  setToken = async () => {
    const token = this.props.match.params.token;
    const cloudauthEnvironment = this.props.match.params.cloudauthEnv;
    const selfServiceEnvironment = this.props.match.params.selfServiceEnv;
    const tokenFound = token || this.props.accountToken;

    if (tokenFound) {
      if (!this.props.accountToken && token) {
        await this.props.setAccountToken(token, cloudauthEnvironment, selfServiceEnvironment);
      }
    }
  }

  startSession = async () => {
    if (this.props.accountToken && this.props.cloudauthEnvironment) {
      await this.props.startSession(this.props.accountToken, this.props.cloudauthEnvironment);
    }
  }

  render() {
    if (!this.props.sessionStarted && !this.props.sessionException) {
      return (
        <LoadingOverlay showOverlay />
      );
    }
    if([
      'iid', 'sms', 'otp',
    ].includes(this.props.modality) &&
        this.props.serverState.temporaryCodeAllowed &&
        this.props.serverState.temporaryCodeAvailable &&
        !this.props.manuallyProceedToTempCode &&
        !this.props.isDeviceManagementWorkflow) {
      console.log('DEFAULT TO TEMP CODE SCREEN');
    }
    return (
      <div>
        <Switch>
          <Route path='/iid-manual' component={ImprivataIdManual} />
          <Route path='/iid' component={ImprivataIdPush} />
          <Route path='/otp' component={Otp} />
          <Route path='/email' component={Email} />
          <Route path='/password' component={Password} />
          <Route path='/password-reset' component={PasswordReset} />
          <Route path='/pin' component={Pin} />
          <Route path='/enrollsms' component={SmsEnroll} />
          <Route path='/enrollimprivataid' component={ImprivataIdEnroll} />
          <Route path='/sms' component={Sms} />
          <Route path='/choosemodality' component={ChooseModality} />
          <Route path='/username-password' component={UsernamePassword} />
          <Route path='/tempcode' component={TempCode} />
          <Route path='/devicelist' component={DeviceList} />
          <Route component={AuthError} />
        </Switch>
        <Footer/>
        <LoadingOverlay showOverlay={this.props.isLoading}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    sessionStarted, startSessionEstablishing, modality, sessionException,
    accountToken, cloudauthEnvironment, selfServiceEnvironment, isLoading,
    username, password, authenticationToken,
    complete, serverState, manuallyProceedToTempCode, isDeviceManagementWorkflow,
  } = state.login;
  return {
    sessionStarted,
    startSessionEstablishing,
    modality,
    sessionException,
    username,
    password,
    authenticationToken,
    complete,
    accountToken,
    cloudauthEnvironment,
    selfServiceEnvironment,
    isLoading,
    serverState,
    manuallyProceedToTempCode,
    isDeviceManagementWorkflow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSession: (accountToken, cloudauthEnvironment) => {
      dispatch(actions.startSession(accountToken, cloudauthEnvironment) );
    },
    setAccountToken: (accountToken, cloudauthEnvironment, selfServiceEnvironment) => {
      dispatch(actions.setAccountToken(accountToken, cloudauthEnvironment, selfServiceEnvironment) );
    },
    setUsername: (username) => {
      dispatch(actions.setInitialUsername(username) );
    },
    setPassword: (password) => {
      dispatch(actions.setInitialPassword(password) );
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Main) );
